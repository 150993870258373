var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "SOP_EMERG_TRAINING_STEP_CD",
      param: _vm.param,
      currentStepCd: _vm.planResultStepCd,
    },
    on: { closePopup: _vm.closePopup },
    model: {
      value: _vm.planResultStepCd,
      callback: function ($$v) {
        _vm.planResultStepCd = $$v
      },
      expression: "planResultStepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }