<template>
    <c-step
      stepperGrpCd="SOP_EMERG_TRAINING_STEP_CD"
      :param="param"
      :currentStepCd="planResultStepCd"
      v-model="planResultStepCd"
      @closePopup="closePopup"
    >
    </c-step>
  </template>
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: 'emergencyTrainingPlanResultDetail',
    props: {
      popupParam: {
        type: Object,
        default: () => ({
          emergTrainingPlanId: '',
          emergTrainingStatusCd: '',
          trainingScenarioId: '',
        }),
      },
      contentHeight: null,
    },
    data() {
      return {
        planResultStepCd: 'SETSC00001',
        editable: true,
      };
    },
    watch: {
    },
    computed: {   //computed는 setter가 없으니 지정해줄 수 없음
      param() {
        return {
          emergTrainingPlanId: this.popupParam.emergTrainingPlanId,
          emergTrainingStatusCd: this.popupParam.emergTrainingStatusCd,
          trainingScenarioId: this.popupParam.trainingScenarioId,
        }
      },
      isOld() {
        return Boolean(this.popupParam.emergTrainingPlanId)
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        // role setting
        this.editable = this.$route.meta.editable;
        // url setting
        this.detailUrl = selectConfig.sop.eap.planResult.plan.get.url;
        // code setting
        // list setting
        this.setStep();
      },
      setStep() {
        if (!this.isOld) {
          this.planResultStepCd = 'SETSC00001';
        } else if (this.popupParam.emergTrainingStatusCd === 'ETSC000002') {
          this.planResultStepCd = 'SETSC00002';
        } else if (this.popupParam.emergTrainingStatusCd === 'ETSC000003') {
          this.planResultStepCd = 'SETSC00002';
        }
      },
      closePopup() {
        this.$emit('closePopup')
      },
    }
  };
  </script>
  